export const state = () => ({
  textModal: "",
});

export const mutations = {
  CHANGE_TEXTMODAL(state, payload) {
    state.textModal = payload;
  },
};

export const actions = {
  UPDATE_TEXTMODAL({ commit }, payload) {
    commit("CHANGE_TEXTMODAL", payload);
  },
};

export const getters = {
  getTextModal(state) {
    return state.textModal;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
