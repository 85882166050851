export class EventSettingServices {
  constructor($axios) {
    this.$axios = $axios;
  }

  async getEventList(body) {
    const { data } = await this.$axios.post(`/Event/GetEventList`, body);
    const response = data;

    return response;
  }

  async getEventData(eventId) {
    const { data } = await this.$axios.get(`/Event/GetEventData/${eventId}`);
    const response = data;

    return response;
  }

  async getZone(eventId, skip, take) {
    const { data } = await this.$axios.get(
      `/Event/GetZone/${eventId}/${skip}/${take}`
    );
    const response = data;

    return response;
  }

  async getTicket(eventId, skip, take) {
    const { data } = await this.$axios.get(
      `/Event/GetTicket/${eventId}/${skip}/${take}`
    );
    const response = data;

    return response;
  }

  async getGallery(eventId, skip, take) {
    const { data } = await this.$axios.get(
      `/Event/GetGallery/${eventId}/${skip}/${take}`
    );
    const response = data;

    return response;
  }

  async insertEventGallery(eventId, body) {
    const { data } = await this.$axios.post(
      `/Event/InsertEventGallery/${eventId}`,
      body
    );
    const response = data;

    return response;
  }

  async insertEventZone(eventId, body) {
    const { data } = await this.$axios.post(
      `/Event/InsertEventEventZone/${eventId}`,
      body
    );
    const response = data;

    return response;
  }

  async getEventGalleryById(eventId, id) {
    const { data } = await this.$axios.get(
      `/Event/GetEventGalleryById/${eventId}/${id}`
    );
    const response = data;

    return response;
  }

  async updateEventGalleryById(eventId, id, body) {
    const { data } = await this.$axios.put(
      `/Event/UpdateEventGallery/${eventId}/${id}`,
      body
    );
    const response = data;

    return response;
  }

  async removeEventGalleryById(eventId, id) {
    const { data } = await this.$axios.delete(
      `/Event/DeleteEventGallery/${eventId}/${id}`
    );
    const response = data;

    return response;
  }

  async getEventZoneById(eventId, id) {
    const { data } = await this.$axios.get(
      `/Event/GetEventZoneById/${eventId}/${id}`
    );
    const response = data;

    return response;
  }

  async removeEventZone(eventId, id) {
    const { data } = await this.$axios.delete(
      `/Event/DeleteEventZone/${eventId}/${id}`
    );
    const response = data;

    return response;
  }

  async updateEventZone(eventId, id, body) {
    const { data } = await this.$axios.put(
      `/Event/UpdateEventZone/${eventId}/${id}`,
      body
    );
    const response = data;

    return response;
  }

  async insertEventTicket(eventId, body) {
    const { data } = await this.$axios.post(
      `/Event/InsertEventTicket/${eventId}`,
      body
    );
    const response = data;

    return response;
  }

  async removeEventTicketId(eventId, id) {
    const { data } = await this.$axios.delete(
      `/Event/DeleteEventTicket/${eventId}/${id}`
    );
    const response = data;

    return response;
  }

  async getEventTicketById(eventId, id) {
    const { data } = await this.$axios.get(
      `/Event/GetEventTicketById/${eventId}/${id}`
    );

    const response = data;

    return response;
  }

  async getRoundTime(eventId, skip, take) {
    const { data } = await this.$axios.get(
      `/Event/GetRoundtime/${eventId}/${skip}/${take}`
    );

    const response = data;

    return response;
  }

  async getCustom(eventId, skip, take) {
    const { data } = await this.$axios.get(
      `/Event/GetCustom/${eventId}/${skip}/${take}`
    );

    const response = data;

    return response;
  }

  async getClose(eventId, skip, take) {
    const { data } = await this.$axios.get(
      `/Event/GetCloseManage/${eventId}/${skip}/${take}`
    );

    const response = data;

    return response;
  }

  async saveEventData(eventId, body) {
    const { data } = await this.$axios.put(
      `/Event/SaveEventData/${eventId}`,
      body
    );
    const response = data;

    return response;
  }

  async getSchedule(eventId) {
    const { data } = await this.$axios.get(`/Event/GetSchedule/${eventId}`);
    const response = data;

    return response;
  }

  async insertProductPriceManage(eventId, body) {
    const { data } = await this.$axios.post(
      `/Event/InsertProductPriceManage/${eventId}`,
      body
    );
    const response = data;

    return response;
  }

  async getRoundtimeById(eventId, productPriceId) {
    const { data } = await this.$axios.get(
      `/Event/GetRoundtimeById/${eventId}/${productPriceId}`
    );
    const response = data;

    return response;
  }

  async updateProductPriceManage(eventId, productPriceId, body) {
    const { data } = await this.$axios.put(
      `/Event/UpdateProductPriceManage/${eventId}/${productPriceId}`,
      body
    );
    const response = data;

    return response;
  }

  async insertCustom(eventId, productPriceId, body) {
    const { data } = await this.$axios.post(
      `/Event/InsertCustom/${eventId}/${productPriceId}`,
      body
    );
    const response = data;

    return response;
  }

  async insertCloseManage(eventId, productPriceId, body) {
    const { data } = await this.$axios.post(
      `/Event/InsertCloseManage/${eventId}/${productPriceId}`,
      body
    );
    const response = data;

    return response;
  }

  async getSpecialProductPriceManageById(eventId) {
    const { data } = await this.$axios.get(
      `/Event/GetSpecialProductPriceManageById/${eventId}`
    );
    const response = data;

    return response;
  }

  async getCloseManageById(eventId) {
    const { data } = await this.$axios.get(
      `/Event/GetCloseManageById/${eventId}`
    );
    const response = data;

    return response;
  }

  async updateCustomAndClose(
    eventId,
    specialPriceManageTypeId,
    specialProductPriceManageGroupId,
    body
  ) {
    const { data } = await this.$axios.put(
      `/Event/UpdateCustom/${eventId}/${specialPriceManageTypeId}/${specialProductPriceManageGroupId}`,
      body
    );
    const response = data;

    return response;
  }

  async updatePreviewOrPublish(eventId, previewOrPublish) {
    const { data } = await this.$axios.put(
      `/Event/UpdatePreviewOrPublish/${eventId}`,
      previewOrPublish,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const response = data;

    return response;
  }

  async updateEventTicket(eventId, id, body) {
    const { data } = await this.$axios.put(
      `/Event/UpdateEventTicket/${eventId}/${id}`,
      body
    );
    const response = data;

    return response;
  }

  async updateSchedule(eventId, body) {
    const { data } = await this.$axios.put(
      `/Event/UpdateSchedule/${eventId}`,
      body
    );
    const response = data;

    return response;
  }

  async updateCloseManage(
    eventId,
    specialPriceManageTypeId,
    specialProductPriceManageGroupId,
    body
  ) {
    const { data } = await this.$axios.put(
      `/Event/UpdateCloseManage/${eventId}/${specialPriceManageTypeId}/${specialProductPriceManageGroupId}`,
      body
    );
    const response = data;

    return response;
  }
}
