import Vue from "vue";
import swal2 from "sweetalert2";
const swal = swal2.mixin({
  confirmButtonText: "Close",
  confirmButtonClass: "btn-primary btn-alert",
});
Vue.prototype.successAlert = (message = "", title = "Success !") =>
  new swal({
    title: `<p class='alert-text'>${title} </p>`,
    text: message,
    icon: "success",
  });
Vue.prototype.errorAlert = (message = "error message", title = "Error !") =>
  new swal({
    title: `<p class='alert-text'>${title}</p>`,
    text: message,
    icon: "error",
  });
Vue.prototype.warningAlert = (
  message = "warning message",
  title = "Warning !"
) =>
  new swal({
    title: `<p class='alert-text'>${title}</p>`,
    text: message,
    icon: "warning",
  });
Vue.prototype.infoAlert = (message = "Info message", title = "Info !") =>
  new swal({
    title: `<p class='alert-text'>${title}</p>`,
    text: message,
    icon: "info",
  });
Vue.prototype.confirmAlert = (
  message = "You can't revert your action",
  title = "Are you sure?",
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel"
) =>
  new swal({
    title: `<p class='alert-text'>${title}</p>`,
    text: message,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
    confirmButtonClass: "btn-primary btn-alert",
    cancelButtonText: cancelButtonText,
    cancelButtonClass: "btn-alert",
    reverseButtons: true,
  });
export default alert;
