export class MasterSerivces {
  constructor($axios) {
    this.$axios = $axios;
  }

  // ? USER DETAIL

  async userDetail() {
    const { data } = await this.$axios.get(`/User/UserDetail`);
    const response = data;

    return response;
  }

  async getOptionPayment(eventId) {
    const { data } = await this.$axios.get(
      `/Options/GetOptionPaymentChanel/${eventId}`
    );
    const response = data;

    return response;
  }

  async getOptionBank() {
    const { data } = await this.$axios.get(`/Options/GetOptionBank`);
    const response = data;

    return response;
  }

  async getStatusOption() {
    const { data } = await this.$axios.get(
      `/Options/GetOptionTransactionStatus`
    );
    const response = data;

    return response;
  }

  async getLanguageOption() {
    const { data } = await this.$axios.get(`/Options/GetOptionLanguage`);
    const response = data;

    return response;
  }

  async getPaymentOption() {
    const { data } = await this.$axios.get(`/Options/GetOptionPaymentType`);
    const response = data;

    return response;
  }

  async getLocationOption() {
    const { data } = await this.$axios.get(`/Options/GetOptionLocation`);
    const response = data;

    return response;
  }

  async getVenueOption(locationId) {
    const { data } = await this.$axios.get(
      `/Options/GetOptionVenue/${locationId}`
    );
    const response = data;

    return response;
  }

  async getRoomOption(venueId) {
    const { data } = await this.$axios.get(`Options/GetOptionRoom/${venueId}`);
    const response = data;

    return response;
  }

  async getEventOption(keyword) {
    const { data } = await this.$axios.post(
      `/Options/GetOptionEvent`,
      keyword || "",
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const response = data;

    return response;
  }

  async getOptionEventZone(eventId) {
    const { data } = await this.$axios.get(
      `/Options/GetOptionEventZone/${eventId}`
    );
    const response = data;

    return response;
  }

  async getOptionTicketClass() {
    const { data } = await this.$axios.get(`/Options/GetOptionTicketClass`);
    const response = data;

    return response;
  }

  async getOptionDayofWeek() {
    const { data } = await this.$axios.get(`/Options/GetOptionDayofWeek`);
    const response = data;

    return response;
  }

  async getOptionTicket(eventId) {
    const { data } = await this.$axios.get(
      `/Options/GetOptionTicket/${eventId}`
    );
    const response = data;

    return response;
  }

  async getOptionCustomType() {
    const { data } = await this.$axios.get(`/Options/GetOptionCustomType`);
    const response = data;

    return response;
  }

  async getOptionRoundTime(eventId) {
    const { data } = await this.$axios.get(
      `/Options/GetOptionRoundTime/${eventId}`
    );
    const response = data;

    return response;
  }

  async getOptionDay(eventId, productPriceId) {
    const { data } = await this.$axios.get(
      `/Options/GetOptionDay/${eventId}/${productPriceId}`
    );
    const response = data;

    return response;
  }

  async getOptionTime(eventId, productPriceId) {
    const { data } = await this.$axios.get(
      `/Options/GetOptionTime/${eventId}/${productPriceId}`
    );
    const response = data;

    return response;
  }

  async getOptionSpecialTicket(eventId, productPriceId) {
    const { data } = await this.$axios.get(
      `/Options/GetOptionTicket/${eventId}/${productPriceId}`
    );
    const response = data;

    return response;
  }

  async getOptionRoundTimeEvent(eventId) {
    const { data } = await this.$axios.get(
      `/Options/GetOptionRoundTimeEvent/${eventId}`
    );
    const response = data;

    return response;
  }

  async getOptionPromocodeType() {
    const { data } = await this.$axios.get(`/Options/GetOptionPromocodeType`);
    const response = data;

    return response;
  }

  async getOptionPromocodeDetail() {
    const { data } = await this.$axios.get(`/Options/GetOptionPromocodeDetail`);
    const response = data;

    return response;
  }

  async getOptionProductPromocodeDetail(promocodeId, body) {
    const { data } = await this.$axios.post(
      `/Options/GetOptionProductPromocodeDetail/${promocodeId}`,
      body
    );
    const response = data;

    return response;
  }

  async getOptionBannerType() {
    const { data } = await this.$axios.get(`/Options/GetOptionBannerType`);
    const response = data;

    return response;
  }

  async getOptionAdminRole(usePermission, userId) {
    const { data } = await this.$axios.get(`/Options/GetOptionAdminRole`, {
      params: {
        usePermission: usePermission,
        userId: userId,
      },
    });
    const response = data;

    return response;
  }

  async getOptionPermissionMenu() {
    const { data } = await this.$axios.get(`/Options/GetOptionPermissionMenu`);
    const response = data;

    return response;
  }

  async getOptionEventGroup(keyword) {
    const { data } = await this.$axios.get(`/Options/GetOptionEventGroup`, {
      params: { keyword },
    });
    return data;
  }

  async getOptionBrand(keyword, usePermission = true) {
    const { data } = await this.$axios.get(`/Options/GetOptionBrand`, {
      params: { keyword, usePermission },
    });
    return data;
  }
  async getOptionCategory(keyword) {
    const { data } = await this.$axios.get(`/Options/GetOptionCategory`, {
      params: { keyword },
    });
    return data;
  }

  async getOptionVenue(keyword) {
    const { data } = await this.$axios.get(`/Options/GetOptionVenue`, {
      params: { keyword },
    });
    return data;
  }

  async getOptionCustomerStatusFlow() {
    const { data } = await this.$axios.get(
      `/Options/GetOptionCustomerStatusFlow`
    );
    return data;
  }

  async getOptionProvince() {
    const { data } = await this.$axios.get(`/Venue/ProvineOption`);
    return data;
  }

  async GetOptionEventGroupInCategory(payload) {
    const { data } = await this.$axios.post(
      "/Options/GetOptionEventGroupInCategory",
      payload
    );
    return data;
  }
}
