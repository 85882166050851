import Vue from "vue";
import Router from "vue-router";
// import RouterPermission from "./middleware/permission";
import { store } from "../store/index";
import configRoutes from "./routes";
import VueCookies from "vue-cookies";
Vue.use(Router);

const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

router.beforeEach(async (to, from, next) => {
  if (!to.meta.requiresAuth) {
    return next();
  } else {
    const cookie = VueCookies.get("back_office_admin_sale_order_token");
    if (cookie) {
      const storeAction = await store.dispatch("getPermission");
      const permission = storeAction;

      if (permission.length > 0) {
        var findPermission = !!permission.find(
          (x) => x.permissionMenuId === to.meta.lv
        );

        if (findPermission === false && to.path !== "/welcome") {
          return next({ path: "/401" });
        }
        
        if (to.path == "/welcome" && from.path == "/") {
          const allowedRoutes = router.options.routes.filter((route) => {
            return permission.some((x) => x.permissionMenuName === route.name);
          });
          if (allowedRoutes.length > 0) {
            const firstAllowedRoute = allowedRoutes[0];
            return next(firstAllowedRoute.path);
          } else {
            return next({ path: "/401" });
          }
        }

        if (
          (to.name == "Service Detail" &&
            from.name == "Create Service BranchList") ||
          (to.name == "Service Detail" &&
            from.name == "Create Service Branch") ||
          (to.name == "Service Detail" && from.name == "Service Branch Detail")
        ) {
          to.query.tab = "4";
        }
      }
    }
  }
  return next();
});

export default router;
