export class UserServices {
  constructor($axios) {
    this.$axios = $axios;
  }

  async searchAdminRole(body) {
    const { data } = await this.$axios.post(`/User/SearchAdminRole`, body);
    const response = data;

    return response;
  }

  async manageAdminRole(id, body) {
    const { data } = await this.$axios.post(
      `/User/ManageAdminRole/${id}`,
      body
    );
    const response = data;

    return response;
  }

  async getPermissionByRoleId(id) {
    const { data } = await this.$axios.get(`/User/GetPermissionByRoleId/${id}`);
    const response = data;

    return response;
  }

  async getAccessTypeIdOption() {
    const { data } = await this.$axios.get("/User/AccessTypeIdOption");
    return data;
  }

  async getLineUser(keyword) {
    const { data } = await this.$axios.get("/User/GetLineUser", {
      params: {
        search: keyword,
      },
    });
    return data;
  }

  async getLineUserDetail(id) {
    const { data } = await this.$axios.get(`/User/GetLineUserDetail`, {
      params: {
        userId: id,
      },
    });
    return data;
  }

  async changeStatusUserAdmin(id, payload) {
    const { data } = await this.$axios.put(
      `/User/ChangeStatusUserAdmin/${id}`,
      payload
    );
    return data;
  }
}
