import Vue from "vue";
import axios from "axios";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export const state = () => ({
  modalAlertLogin: false,
  respUpload: {},
  emails: []
});

export const mutations = {
  setModalAlertLogin: async function (state, val) {
    state.modalAlertLogin = val;
  },
  setRespUpload: async function (state, val) {
    state.respUpload = val;
  },
  setEmails: async function (state, val) {
    state.emails = val;
  },
};

export const actions = {
  uploadFile: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/import/importexcel-ba`, payload)
      .then(
        async (data) => {
          commit("setRespUpload", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getEmails: async ({ commit }) => {
    await axios
      .get(`${process.env.VUE_APP_API}/import/email`)
      .then(
        async (data) => {
          commit("setEmails", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
};

export default {
  state,
  actions,
  mutations,
};
