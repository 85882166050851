import Vue from "vue";
import axios from "axios";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export const state = () => ({
  modalAlertLogin: false,
  formList: {},
  respDelete: {},
});

export const mutations = {
  setModalAlertLogin: async function (state, val) {
    state.modalAlertLogin = val;
  },
  setSaleOrderList: async function (state, val) {
    state.formList = val;
  },
  setRespDelete: async function (state, val) {
    state.respDelete = val;
  },
};

export const actions = {
  getSaleOrderList: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/saleorder/admin/search`, payload)
      .then(
        async (data) => {
          commit("setSaleOrderList", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  deleteSaleOrder: async ({ commit }, payload) => {
    await axios
      .delete(`${process.env.VUE_APP_API}/saleorder/admin/delete/${payload}`)
      .then(
        async (data) => {
          commit("setRespDelete", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
};

export default {
  state,
  actions,
  mutations,
};
