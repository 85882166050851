export class BannerServices {
  constructor($axios) {
    this.$axios = $axios;
  }

  async getBanner(skip, take) {
    const { data } = await this.$axios.get(`/Banner/GetBanner/${skip}/${take}`);
    const response = data;

    return response;
  }

  async manageBanner(id, body) {
    const { data } = await this.$axios.post(`/Banner/ManageBanner/${id}`, body);
    const response = data;

    return response;
  }

  async deleteBanner(id) {
    const { data } = await this.$axios.delete(`/Banner/Delete/${id}`);
    const response = data;

    return response;
  }
}
