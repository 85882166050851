export class ServiceBranchServices {
  constructor($axios) {
    this.$axios = $axios;
  }

  async getEventData(id) {
    const { data } = await this.$axios.get(`/EventGroup/GetEventData/${id}`);
    return data;
  }

  async createServiceBranch(payload) {
    const { data } = await this.$axios.post(`/EventGroup/InsertEvent`, payload);
    return data;
  }

  async saveServiceBranch(id, payload) {
    const { data } = await this.$axios.put(
      `/EventGroup/SaveEventData/${id}`,
      payload
    );
    return data;
  }

  async getBranchList(payload, usePermission) {
    console.log(usePermission);
    const { data } = await this.$axios.post(
      `/EventGroup/GetBranchList`,
      payload,
      {
        params: {
          usePermission: usePermission ? usePermission : false,
        },
      }
    );
    return data;
  }

  async updatePreviewOrPublish(id, payload) {
    const { data } = await this.$axios.put(
      `/EventGroup/UpdatePreviewOrPublish/${id}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  }
}
