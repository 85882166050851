import Axios from "axios";
import { UtilsServices } from "./utils/utils.services";
import { AuthServices } from "./auth/auth.services";
import { TransactionServices } from "./transaction/transaction.services";

import Cookies from "vue-cookies";
import { MasterSerivces } from "./master/master.services";
import { EventSettingServices } from "./eventlist/eventlist.services";
import { CreateEventServices } from "./createevent/createevent.services";
import { PromocodeServices } from "./promocode/promocode.services";
import { BannerServices } from "./banner/banner.services";
import { AdminServices } from "./admin/admin.services";
import { UserServices } from "./user/user.services";
import { BrandServices } from "./brand/brand.services";
import { CategoryServices } from "./category/category.service";
import { VenueServices } from "./venue/venue.services";
import { EventGroupServices } from "./eventgroup/eventgroup.services";
import {
  ServiceBranch,
  ServiceBranchServices,
} from "./servicebranch/servicebranch.services";
import { DashboardServices } from "./dashboard/dashboard.services";
import router from "../router/index";

const api = Axios.create({
  baseURL:
    process.env.VUE_APP_MODE == "production"
      ? process.env.VUE_APP_API_PROD
      : process.env.VUE_APP_API,
});

api.interceptors.request.use(
  (res) => {
    // event api called
    const token = Cookies.get("back_office_admin_sale_order_token");
    if (token) {
      res.headers["Authorization"] = `Bearer ${token}`;
    }

    res.headers["API-KEY"] = process.env.VUE_APP_API_KEY;
    res.headers["Culture"] = "th";

    return res;
  },
  (err) => {
    console.log(err);

    return Promise.reject(err);
  }
);

api.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    const token = Cookies.get("back_office_admin_sale_order_token");
    console.log(JSON.stringify(error));

    if (token === null) {
      router.push("/");
    }

    return Promise.reject(error);
  }
);

const services = {
  auth: new AuthServices(api),
  master: new MasterSerivces(api),
  transaction: new TransactionServices(api),
  event: new EventSettingServices(api),
  createevent: new CreateEventServices(api),
  promocode: new PromocodeServices(api),
  banner: new BannerServices(api),
  admin: new AdminServices(api),
  user: new UserServices(api),
  brand: new BrandServices(api),
  category: new CategoryServices(api),
  venue: new VenueServices(api),
  eventgroup: new EventGroupServices(api),
  servicebranch: new ServiceBranchServices(api),
  dashboard: new DashboardServices(api),
  utils: new UtilsServices(),
};

export default services;
