export class CategoryServices {
  constructor($axios) {
    this.$axios = $axios;
  }

  async getCategoryList(skip, take, keyword) {
    const params = {
      skip,
      take,
      keyword,
    };
    const { data } = await this.$axios.get("/Category/List", { params });
    return data;
  }

  async getCategoryById(id) {
    const { data } = await this.$axios.get(`/Category/${id}`);
    return data;
  }

  async saveCategory(payload) {
    const { data } = await this.$axios.post(`/category/save`, { ...payload });
    return data;
  }

  async setStatus(id, status) {
    const params = {
      isActive: status,
    };
    const { data } = await this.$axios.put(`/category/${id}/setStatus`, null, {
      params: {
        ...params,
      },
    });
    return data;
  }
}
