const level = 1;
const levelCreate = 14;
const Transaction = {
  path: "",
  name: "",
  redirect: "/transaction",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/transaction",
      redirect: "/transaction",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Transaction",
      meta: {
        requiresAuth: true,
        activeUrl: "/transaction",
        parent: "/transaction",
        lv: level,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/transaction"),
          meta: {
            requiresAuth: true,
            activeUrl: "/transaction",
            parent: "/transaction",
            lv: level,
          },
        },
        {
          path: "/transaction/details/:id",
          name: "Transaction",
          component: () =>
            import("@/views/pages/transaction/transactiondetail"),
          meta: {
            requiresAuth: true,
            lv: level,
            isChildren: true,
            activeUrl: "/transaction",
          },
        },
        {
          path: "/transaction/create",
          name: "Transaction",
          component: () =>
            import("@/views/pages/transaction/createTransaction"),
          meta: {
            requiresAuth: true,
            lv: levelCreate,
            isChildren: true,
            activeUrl: "/transaction",
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
export default Transaction;
