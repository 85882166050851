const level = 11;
const Dashboard = {
  path: "",
  name: "",
  redirect: "/dashboard",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/dashboard",
      redirect: "/dashboard",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Dashboard",
      meta: {
        requiresAuth: true,
        activeUrl: "/dashboard",
        parent: "/dashboard",
        lv: level,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/dashboard"),
          meta: {
            requiresAuth: true,
            activeUrl: "/dashboard",
            parent: "/dashboard",
            lv: level,
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};

export default Dashboard;
