const level = 10;
const levelCreateService = 12;
const levelCreateServiceBranch = 13;
const levelCreateServiceBranchList = 13;
const Service = {
  path: "",
  name: "",
  redirect: "/service",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/service",
      redirect: "/service",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Service List",
      meta: {
        requiresAuth: true,
        activeUrl: "/service",
        parent: "/service",
        lv: level,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/service"),
          meta: {
            requiresAuth: true,
            activeUrl: "/service",
            parent: "/service",
            lv: level,
          },
        },
        {
          path: "/createService",
          name: "Create Service",
          component: () => import("@/views/pages/service/createService"),
          meta: {
            requiresAuth: true,
            activeUrl: "/service",
            isChildren: true,
            lv: levelCreateService,
          },
        },
        {
          path: "/service/:id/createServiceBranch",
          name: "Create Service Branch",
          component: () => import("@/views/pages/service/createServiceBranch"),
          meta: {
            requiresAuth: true,
            activeUrl: "/service",
            lv: levelCreateServiceBranch,
          },
        },
        {
          path: "/service/:id/createServiceBranchList",
          name: "Create Service Branch",
          component: () =>
            import("@/views/pages/service/createServiceBranchList"),
          meta: {
            requiresAuth: true,
            lv: levelCreateServiceBranchList,
            activeUrl: "/service",
          },
        },

        {
          path: "/servicebranch/:eventGroupId/detail/:id",
          name: "Service Branch Detail",
          component: () => import("@/views/pages/servicebranch/detail"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            lv: level,
            activeUrl: "/service",
          },
        },
        {
          path: "/service/detail/:id",
          name: "Service Detail",
          component: () => import("@/views/pages/service/detail"),
          meta: {
            requiresAuth: true,
            lv: level,
            isChildren: true,
            activeUrl: "/service",
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
export default Service;
