export class TransactionServices {
  constructor($axios) {
    this.$axios = $axios;
  }

  async getTransactionList(body) {
    try {
      const { data } = await this.$axios.post(
        `/Transaction/SearchTransaction`,
        body
      );

      const response = data;

      return response;
    } catch (error) {
      return {
        result: 0,
      };
    }
  }

  async getTransactionDetail(refId, payload) {
    const { data } = await this.$axios.get(
      `/Transaction/TransactionItemInfo/${refId}`,
      { params: { ...payload } }
    );
    const response = data;

    return response;
  }

  async getPaymentInfo(refId) {
    const { data } = await this.$axios.get(`/Transaction/PaymentInfo/${refId}`);
    const response = data;

    return response;
  }

  async submitTransaction(refId, body) {
    const { data } = await this.$axios.post(
      `/Transaction/SubmitTransactionStatus/${refId}`,
      body
    );
    const response = data;

    return response;
  }

  async exportTransactionByFilter(enpoint, body) {
    const { data } = await this.$axios.post(`${enpoint}`, body, {
      responseType: "blob",
    });

    const response = data;

    return response;
  }

  async cancelTransaction(refId, canceledNote) {
    const { data } = await this.$axios.put(
      `/Transaction/CancelTransaction/${refId}`,
      null,
      {
        params: {
          canceledNote,
        },
      }
    );
    return data;
  }

  async insertUserCart(payload) {
    const { data } = await this.$axios.post(
      `/Transaction/InsertUserCart`,
      payload
    );
    return data;
  }

  async updateUserCartInfomation(payload) {
    const { data } = await this.$axios.put(
      `/Transaction/UpdateUserCartInfomation`,
      payload
    );
    return data;
  }

  async submitBooking(payload) {
    const { data } = await this.$axios.post(
      `/Transaction/SubmitBooking`,
      payload
    );
    return data;
  }
}
