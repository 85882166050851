import axios from "axios";
export const state = () => ({
  state: {
    modalAlertLogin: false,
    themeLogo: {},
    Logo: "",
  },
});

export const mutations = {
  setTheme: async function (state, val) {
    state.themeLogo = val;
    state.Logo = val.themeLogoDomain + val.result.themeLogoHomePage;
  },
};

export const actions = {
  getTheme: async ({ commit }) => {
    const endpoint =
      process.env.VUE_APP_MODE == "production"
        ? process.env.VUE_APP_API_TEMPLATE_PROD
        : process.env.VUE_APP_API_TEMPLATE;

    await axios.get(`${endpoint}/Home/GetThemeSetting`).then(
      async (data) => {
        commit("setTheme", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
};
export const getters = {
  theme(state) {
    return state.themeLogo;
  },
  LogoHeader(state) {
    return state.Logo;
  },
};
export default {
  state,
  actions,
  mutations,
  getters,
};
