import Vue from "vue";
import axios from "axios";
import VueCookies from "vue-cookies";
import services from "../../services/services";
Vue.use(VueCookies);

export const state = () => ({
  Permission: [],
  UserDetail: {},
});

export const mutations = {
  setPermission: async function (state, val) {
    state.Permission = val;
  },
  setUserDetail: async function (state, val) {
    state.UserDetail = val;
  },
};

export const actions = {
  getPermission: async ({ commit }, payload) => {
    return await services.master.userDetail().then(
      async (data) => {
        if (data.result == 1) {
          commit("setPermission", data.detail.permission);
          commit("setUserDetail", data.detail);
          return data.detail.permission;
        }
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
};

export const getters = {
  getPermission(state) {
    return state.Permission;
  },
  getUserDetail(state) {
    return state.UserDetail;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
