export class PromocodeServices {
  constructor($axios) {
    this.$axios = $axios;
  }

  async getPromocodeDetail(eventId) {
    const { data } = await this.$axios.get(
      `/Promocode/GetPromocodeDetail/${eventId}`
    );

    const response = data;

    return response;
  }

  async searchPromocode(body) {
    const { data } = await this.$axios.post(`/Promocode/SearchPromocode`, body);
    const response = data;

    return response;
  }

  async savePromocodeData(promocodeId, body) {
    const { data } = await this.$axios.post(
      `/Promocode/SavePromocodeData/${promocodeId}`,
      body
    );
    const response = data;

    return response;
  }

  async switchActivePromocode(promocodeId, isActive) {
    const { data } = await this.$axios.put(
      `/Promocode/SwitchActivePromocode/${promocodeId}`,
      isActive,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const response = data;

    return response;
  }

  async getPromocodeGeneratedDetail(promocodeId, body) {
    const { data } = await this.$axios.post(
      `/Promocode/GetPromocodeGeneratedDetail/${promocodeId}`,
      body
    );
    const response = data;

    return response;
  }

  async exportPromocodeGeneratedDetail(promocodeId, body) {
    const { data } = await this.$axios.post(
      `/Promocode/ExportPromocodeGeneratedDetail/${promocodeId}`,
      body,
      {
        responseType: "blob",
      }
    );
    const response = data;
    

    return response;
  }
}
