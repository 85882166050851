export class AdminServices {
  constructor($axios) {
    this.$axios = $axios;
  }

  async createUserAdmin(body) {
    const { data } = await this.$axios.post(`/User/CreateUserAdmin`, body);
    const response = data;

    return response;
  }
  async updateUserAdmin(id, body) {
    const { data } = await this.$axios.post(
      `/User/UpdateUserAdmin/${id}`,
      body
    );
    const response = data;

    return response;
  }

  async searchUserAdmin(body) {
    const { data } = await this.$axios.post(`/User/SearchUserAdmin`, body);
    const response = data;

    return response;
  }

  async getUserById(id) {
    const { data } = await this.$axios.get(`/User/GetUserById/${id}`);
    const response = data;

    return response;
  }

  async resetPasswordAdmin(id, body) {
    const { data } = await this.$axios.post(
      `/User/ResetPasswordAdmin/${id}`,
      body
    );
    const response = data;

    return response;
  }
}
