<template>
  <div class="mb-2">
    <div class="main-label">{{ textFloat }}:</div>
    <div class="input-container">
      <datetime
        type="date"
        placeholder="Please Select Date"
        format="dd/MM/yyyy"
        value-zone="local"
        :value="value"
        :ref="`ref${name}`"
        :max-datetime="maxDatetime"
        :min-datetime="minDatetime"
        @input="$emit('input', $event)"
      >
      </datetime>
      <div
        class="icon-primary text-right"
        @click="$refs[`ref${name}`].isOpen = true"
      >
        <font-awesome-icon
          icon="calendar-alt"
          class="pointer g-text-gold"
          color="#B41BB4"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    textFloat: {
      required: true,
      type: String,
    },
    name: {
      required: false,
      type: String,
    },
    value: {
      required: false,
      type: String,
    },
    minDatetime: {
      required: false,
      type: String,
    },
    maxDatetime: {
      required: false,
      type: String,
    },
  },
};
</script>

<style scoped>
.title {
  margin-bottom: 0.4rem;
  font-weight: 600;
}
</style>
