const level = 8;
const Category = {
    path: "",
    name: "",
    redirect: "/category",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "/category",
        redirect: "/category",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        name: "Category List",
        meta: {
          requiresAuth: true,
          activeUrl: "/category",
          parent: "/category",
          lv: level,
        },
        children: [
          {
            path: "",
            name: "",
            component: () => import("@/views/pages/category"),
            meta: {
              requiresAuth: true,
              activeUrl: "/category",
              parent: "/category",
              lv: level,
            },
          },
        ],
      },
    ],
    meta: { requiresAuth: true },
  };
  export default Category;