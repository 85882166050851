export class DashboardServices {
  constructor($axios) {
    this.$axios = $axios;
  }

  async getSummaryDateOption() {
    const { data } = await this.$axios.get("/Report/SummaryDateOption");
    return data;
  }

  async getSummary(payload) {
    const { data } = await this.$axios.post(`/Report/Summary`, payload);
    return data;
  }

  async getReportBrand(payload) {
    const { data } = await this.$axios.post(`/Report/Brand`, payload);
    return data;
  }
  async getReportCategory(payload) {
    const { data } = await this.$axios.post(`/Report/Category`, payload);
    return data;
  }
  async getReportBranch(payload) {
    const { data } = await this.$axios.post(`/Report/Branch`, payload);
    return data;
  }
  async getReportEventGroup(payload) {
    const { data } = await this.$axios.post(`/Report/EventGroup`, payload);
    return data;
  }

  async getReportBarBooking(payload) {
    const { data } = await this.$axios.post(`/Report/Bar/Booking`, payload);
    return data;
  }
  async getReportTransaction(payload) {
    const { data } = await this.$axios.post(`/Report/Transaction`, payload);
    return data;
  }
  async getReportPieCustomer(payload) {
    const { data } = await this.$axios.post(`/Report/Pie/Customer`, payload);
    return data;
  }

  async getReportCustomer(payload) {
    const { data } = await this.$axios.post(`/Report/Customer`, payload);
    return data;
  }

  async exportReportExcel(table, payload) {
    const { data } = await this.$axios.post(
      `/Report/Export/${table}`,
      payload,
      {
        responseType: "blob",
      }
    );
    return data;
  }
}
