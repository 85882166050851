import {
  Transaction,
  EventSetting,
  CreateEvent,
  Promocode,
  Banner,
  Admin,
  Role,
  Brand,
  Category,
  Branch,
  CreateBranch,
  Service,
  ServiceBranch,
  Welcome,
  Dashboard,
} from "../path";

function configRoutes() {
  return [
    {
      path: "/",
      name: "Login",
      component: () => import("@/views/pages/Login"),
      meta: { requiresAuth: false, lv: 1 },
    },
    {
      path: "/welcome",
      name: "Welcome",
      redirect: "/welcome",
      component: () => import("@/containers/TheContainer"),
      meta: { requiresAuth: true, lv: 1 },
      children: [Welcome],
    },
    {
      path: "/transaction",
      name: "Transaction",
      redirect: "/transaction",
      component: () => import("@/containers/TheContainer"),
      meta: { requiresAuth: true, lv: 1 },
      children: [Transaction],
    },
    {
      path: "/event",
      name: "Event",
      redirect: "/event",
      component: () => import("@/containers/TheContainer"),
      meta: { requiresAuth: true, lv: 1 },
      children: [EventSetting],
    },
    {
      path: "/createevent",
      name: "Event",
      redirect: "/createevent",
      component: () => import("@/containers/TheContainer"),
      meta: { requiresAuth: true, lv: 1 },
      children: [CreateEvent],
    },
    {
      path: "/promocode",
      name: "Promocode",
      redirect: "/promocode",
      component: () => import("@/containers/TheContainer"),
      meta: { requiresAuth: true, lv: 1 },
      children: [Promocode],
    },
    {
      path: "/banner",
      name: "Banner",
      redirect: "/banner",
      component: () => import("@/containers/TheContainer"),
      meta: { requiresAuth: true, lv: 1 },
      children: [Banner],
    },
    {
      path: "/brand",
      name: "Brand",
      redirect: "/brand",
      component: () => import("@/containers/TheContainer"),
      meta: { requiresAuth: true, lv: 1 },
      children: [Brand],
    },
    {
      path: "/category",
      name: "Category",
      redirect: "/category",
      component: () => import("@/containers/TheContainer"),
      meta: { requiresAuth: true, lv: 1 },
      children: [Category],
    },
    {
      path: "/branch",
      name: "Branch",
      redirect: "/branch",
      component: () => import("@/containers/TheContainer"),
      meta: { requiresAuth: true, lv: 1 },
      children: [Branch],
    },
    {
      path: "/service",
      name: "Service",
      redirect: "/service",
      component: () => import("@/containers/TheContainer"),
      meta: { requiresAuth: true, lv: 1 },
      children: [Service],
    },

    {
      path: "/dashboard",
      name: "Dashboard",
      redirect: "/dashboard",
      component: () => import("@/containers/TheContainer"),
      meta: { requiresAuth: true, lv: 1 },
      children: [Dashboard],
    },
    {
      path: "/admin",
      name: "Admin",
      redirect: "/admin",
      component: () => import("@/containers/TheContainer"),
      meta: { requiresAuth: true, lv: 1 },
      children: [Admin],
    },
    {
      path: "/role",
      name: "Role",
      redirect: "/role",
      component: () => import("@/containers/TheContainer"),
      meta: { requiresAuth: true, lv: 1 },
      children: [Role],
    },
    {
      path: "/500",
      name: "Page500",
      component: () => import("@/views/pages/Page500"),
    },
    {
      path: "/403",
      name: "Page403",
      component: () => import("@/views/pages/Page403"),
    },
    {
      path: "/401",
      name: "Page401",
      component: () => import("@/views/pages/Page401"),
      meta: { requiresAuth: false, lv: 1 },
    },
    {
      path: "*",
      name: "Page404",
      component: () => import("@/views/pages/Page404"),
    },
  ];
}
export default configRoutes;
