import connectAPI from '../connectAPI/connectAPI'

const moduleConnectAPI = {
  state: {
    stateLogin: null,
    stateLogout: null,
    // news
    // stateNews: null,
    // stateNewByID: null,
    // stateCreateNews: null,
    // stateUpdatetNews: null,
    // stateDeleteNews: null,
    // stateUploadImage: null,
  },
  mutations: {
    mutationLogin (state, data) {
      state.stateLogin = data
    },
    mutationLogout (state, data) {
      state.stateLogout = data
    },
    //news
    // mutationGetNews (state, data) {
    //   state.stateNews = data
    // },
    // mutationGetNewByID (state, data) {
    //   state.stateNewByID = data
    // },
    // mutationCreateNews (state, data) {
    //   state.stateCreateNews = data
    // },
    // mutationUpdatetNews (state, data) {
    //   state.stateUpdatetNews = data
    // },
    // mutationDeleteNews (state, data) {
    //   state.stateDeleteNews = data
    // },
    // mutationGetNewsType (state, data) {
    //   state.stateGetNewsType = data
    // },
    // mutationUploadImage (state, data) {
    //   state.stateUploadImage = data
    // },
  },
  actions: {
    async ActionLogin (contaxt, data) {
      const dataFromAxios = await connectAPI.Login(data)
      await contaxt.commit('mutationLogin', dataFromAxios)
    },
    async ActionLogout (contaxt, data) {
      const dataFromAxios = await connectAPI.Logout(data)
      await contaxt.commit('mutationLogout', dataFromAxios)
    },
    // news
    // async ActionGetNews (contaxt, data) {
    //   const dataFromAxios = await connectAPI.GetNews(data)
    //   await contaxt.commit('mutationGetNews', dataFromAxios)
    // },
    // async ActionGetNewByID (contaxt, data) {
    //   const dataFromAxios = await connectAPI.GetNewByID(data)
    //   await contaxt.commit('mutationGetNewByID', dataFromAxios)
    // },
    // async ActionCreateNews (contaxt, data) {
    //   const dataFromAxios = await connectAPI.CreateNews(data)
    //   await contaxt.commit('mutationCreateNews', dataFromAxios)
    // },
    // async ActionUpdatetNews (contaxt, data) {
    //   const dataFromAxios = await connectAPI.UpdatetNews(data)
    //   await contaxt.commit('mutationUpdatetNews', dataFromAxios)
    // },
    // async ActionDeleteNews (contaxt, data) {
    //   const dataFromAxios = await connectAPI.DeleteNews(data)
    //   await contaxt.commit('mutationDeleteNews', dataFromAxios)
    // },
    // async ActionGetNewsType (contaxt) {
    //   const dataFromAxios = await connectAPI.GetNewsType()
    //   await contaxt.commit('mutationGetNewsType', dataFromAxios)
    // },
    // async ActionGetNotificationNews (contaxt, data) {
    //   const dataFromAxios = await connectAPI.GetNotificationNews(data)
    //   await contaxt.commit('mutationGetNotificationNews', dataFromAxios)
    // },
    // async ActionUploadImage (contaxt, data) {
    //   const dataFromAxios = await connectAPI.UploadImage(data)
    //   await contaxt.commit('mutationUploadImage', dataFromAxios)
    // },
  }
}

export default moduleConnectAPI
