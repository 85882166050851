const level = 5;
const Admin = {
  path: "",
  name: "",
  redirect: "/admin",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/admin",
      redirect: "/admin",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Admin List",
      meta: {
        requiresAuth: true,
        activeUrl: "/admin",
        parent: "/admin",
        lv: level,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/admin"),
          meta: {
            requiresAuth: true,
            activeUrl: "/admin",
            parent: "/admin",
            lv: level,
          },
        },
        {
          path: "/admin/create",
          name: "Create admin",
          component: () => import("@/views/pages/admin/createadmin"),
          meta: {
            requiresAuth: true,
            lv: level,
            isChildren: true,
            activeUrl: "/admin",
          },
        },
        {
          path: "/admin/edit/:id",
          name: "Edit admin",
          component: () => import("@/views/pages/admin/createadmin"),
          meta: {
            requiresAuth: true,
            lv: level,
            isChildren: true,
            activeUrl: "/admin",
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
export default Admin;
