import Vue from "vue";
import axios from "axios";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export const state = () => ({
  modalAlertLogin: false,
  respCreate: {},
});

export const mutations = {
  setModalAlertLogin: async function (state, val) {
    state.modalAlertLogin = val;
  },
  setRespCreate: async function (state, val) {
    state.respCreate = val;
  },
};

export const actions = {
  createSaleOrder: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/saleorder/admin/create`, payload)
      .then(
        async (data) => {
          commit("setRespCreate", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
};

export default {
  state,
  actions,
  mutations,
};
