export class CreateEventServices {
  constructor($axios) {
    this.$axios = $axios;
  }

  async insertEvent(body) {
    const { data } = await this.$axios.post(`/Event/InsertEvent`, body);
    const response = data;

    return response;
  }
}
