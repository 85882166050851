export class AuthServices {
  constructor($axios) {
    this.$axios = $axios;
  }

  async onLogin(body) {
    const { data } = await this.$axios.post(`/User/Login`, body);
    const response = data;

    return response;
  }
}
