const level = 4;
const Banner = {
  path: "",
  name: "",
  redirect: "/banner",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/banner",
      redirect: "/banner",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Banner List",
      meta: {
        requiresAuth: true,
        activeUrl: "/banner",
        parent: "/banner",
        lv: level,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/banner"),
          meta: {
            requiresAuth: true,
            activeUrl: "/banner",
            parent: "/banner",
            lv: level,
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
export default Banner;
