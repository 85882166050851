const level = 2;
const EventSetting = {
  path: "",
  name: "",
  redirect: "/event",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/event",
      redirect: "/event",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Event List",
      meta: {
        requiresAuth: true,
        activeUrl: "/event",
        parent: "/event",
        lv: level,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/eventsetting"),
          meta: {
            requiresAuth: true,
            activeUrl: "/event",
            parent: "/event",
            lv: level,
          },
        },
        {
          path: "/event/details/:id",
          name: "Event List",
          component: () =>
            import("@/views/pages/eventsetting/eventsettingdetail"),
          meta: {
            requiresAuth: true,
            lv: level,
            isChildren: true,
            activeUrl: "/event",
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
export default EventSetting;
