import Vue from "vue";
import axios from "axios";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export const state = () => ({
  ShortProfile: {
    email: "",
    userName: "",
    id: "",
    phoneNumber: ""
  },
});

export const mutations = {
  setShortProfile: async function (state, val) {
    state.ShortProfile = val;
  },
};

export const actions = {
  getShortProfile: async ({ commit }, payload) => {
    return axios(`${process.env.VUE_APP_API}/user/profile`).then(
      async (data) => {
        if (data.data.result == 1) {
          commit("setShortProfile", data.data.detail);
          return data.data.detail;
        }
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
};

export default {
  state,
  actions,
  mutations,
};
