import axios from 'axios'
// import VueCookies from 'vue-cookies';
// import swal from 'sweetalert';

export default {
  async Login (form) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_API}/auth/login`, form)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  async Logout (form) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_API}/auth/logout`, form)
      return response.data
    } catch (error) {
      return error.response
    }
  },
}
