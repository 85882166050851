export class EventGroupServices {
  constructor($axios) {
    this.$axios = $axios;
  }

  async getEventGroupList(payload) {
    const { data } = await this.$axios.post(`/EventGroup/List`, payload);
    return data;
  }
  async createEventGroup(payload) {
    const { data } = await this.$axios.post(`/EventGroup/save`, payload);
    return data;
  }

  async eventGroupDetail(id) {
    const { data } = await this.$axios.get(`/EventGroup/${id}/Detail`);
    return data;
  }
  async getBannerTemplate(id, skip, take) {
    const { data } = await this.$axios.get(
      `/EventGroup/${id}/BannerTemplate/${skip}/${take}`
    );
    return data;
  }

  async saveEventGroup(id, payload) {
    const { data } = await this.$axios.put(`/EventGroup/${id}/save`, payload);
    return data;
  }

  async insertBannerTemplate(eventGroupId, payload) {
    const { data } = await this.$axios.post(
      `/EventGroup/${eventGroupId}/BannerTemplate/insert`,
      payload
    );
    return data;
  }
  async updateBannerTemplate(eventGroupId, id, payload) {
    const { data } = await this.$axios.post(
      `/EventGroup/${eventGroupId}/BannerTemplate/${id}/update`,
      payload
    );
    return data;
  }

  async getAdditionalTemplate(eventGroupId) {
    const { data } = await this.$axios.get(
      `/EventGroup/GetEventTemplateData/${eventGroupId}`
    );
    return data;
  }

  async savePublish(eventGroupId, isPublish) {
    const { data } = await this.$axios.get(
      `/EventGroup/${eventGroupId}/SavePublish`,
      {
        params: { isPublish: isPublish },
      }
    );

    return data;
  }

  async getCalendar(month, year, eventTypeId, eventId) {
    const { data } = await this.$axios.get(
      `/EventGroup/Calendar/${month}/${year}/${eventTypeId}/${eventId}`
    );
    return data;
  }
  async getRoundTime(date, eventTypeId, eventId) {
    const { data } = await this.$axios.get(
      `/EventGroup/RoundTime/${date}/${eventTypeId}/${eventId}`
    );
    return data;
  }

  async getProduct(date, eventTypeId, eventId, eventDateId, roundTimeEventId) {
    const { data } = await this.$axios.get(
      `EventGroup/GetProductPrice/${date}/${eventTypeId}/${eventId}/${eventDateId}/${roundTimeEventId}`
    );
    return data;
  }

  async calculatePrice(payload) {
    const { data } = await this.$axios.post(
      "/EventGroup/CalculatePrice",
      payload
    );
    return data;
  }

  async saveStatus(eventId, isActive) {
    const { data } = await this.$axios.get(
      `/EventGroup/${eventId}/SaveStatus`,

      {
        params: {
          isActive,
        },
      }
    );
    return data;
  }

  async getDescriptionTemplate(eventGroupId) {
    const { data } = await this.$axios.get(
      `/EventGroup/${eventGroupId}/DescriptionTemplate`
    );
    return data;
  }

  async insertEventList(payload) {
    const { data } = await this.$axios.post(
      `/EventGroup/InsertEventList`,
      payload
    );
    return data;
  }

  async validateProductPriceManageEventList(payload) {
    const { data } = await this.$axios.post(
      `/EventGroup/ValidateProductPriceManageEventList`,
      payload
    );
    return data;
  }

  async ExportActivityLog() {
    const { data } = await this.$axios.post(
      `/EventGroup/ExportActivityLog`,
      null,
      {
        responseType: "blob",
      }
    );
    return data;
  }
}
