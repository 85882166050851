export class BrandServices {
  constructor($axios) {
    this.$axios = $axios;
  }

  async getBrandList(skip, take, keyword) {
    const params = {
      skip,
      take,
      keyword,
    };
    const { data } = await this.$axios.get("/Brand/GetBrand", { params });
    return data;
  }

  async getBrandById(id) {
    const { data } = await this.$axios.get(`/Brand/GetBrand/${id}`);
    return data;
  }

  async insertBrand(payload) {
    const { data } = await this.$axios.post(`/Brand/InsertBrand`, {
      ...payload,
    });
    return data;
  }

  async updateBrand(payload, id) {
    const { data } = await this.$axios.put(`/Brand/UpdateBrand/${id}`, {
      ...payload,
    });
    return data;
  }

  async deleteBrand(flag, id) {
    const { data } = await this.$axios.delete(`/Brand/DeleteBrand/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      data: flag,
    });
    return data;
  }
}
