const level = 7;
const Brand = {
  path: "",
  name: "",
  redirect: "/brand",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/brand",
      redirect: "/brand",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Brand List",
      meta: {
        requiresAuth: true,
        activeUrl: "/brand",
        parent: "/brand",
        lv: level,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/brand"),
          meta: {
            requiresAuth: true,
            activeUrl: "/brand",
            parent: "/brand",
            lv: level,
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
export default Brand;
