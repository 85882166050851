import * as moment from "moment/moment";
export class UtilsServices {
  formatNumber(number) {
    return number.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  fillterLang(arr, languageId) {
    const data = arr.find((x) => x.languageId == languageId);

    return data;
  }

  formatTime(time) {
    let final = "";
    time = time.split(":");
    final = `${time[0]}:${time[1]}`;

    return final;
  }

  resolveAndDownloadBlob(response, fileName) {
    const url = window.URL.createObjectURL(response);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}_${moment(Date.now()).format("YYYY-MM-DD")}.xlsx`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
  generateUUID() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }
}
